export const environment = {
  production: true,
  hmr: false,
  showConsoleLog: false,
  apiUrl: 'https://ecmsapi.judiciary.na.gov.ng/api',
  courtTitle: 'HIGH COURT OF NASARAWA',
  courtTitleSentence: 'Federal High Court of Nigeria',
  stateTitle: 'NASARAWA STATE OF NIGERIA',
  stateTitleSentence: 'Nasarawa State Of Nigeria',
  deptTitle: 'PROBATE DEPARTMENT',
  BASE_URL: 'https://ecmsapi.judiciary.na.gov.ng/api',
  BASE_URL_II: 'https://ecmsapi.judiciary.na.gov.ng/api',
  API_URL: '/',
  PORTAL_URL: 'https://portal.fcthighcourt.gov.ng',
  currentState: 'Nasarawa State',
  court_bg_path: 'assets/images/court-bg.png',
  court_logo_path: 'assets/images/nas-afid-logo.png',
  court_logo_1: 'assets/img/coat-of-arms-tp.png',
  court_logo_2: 'assets/images/nas-afid-logo.png',
  // paystack_public_key: 'pk_live_b1886cbd84b9eb8a5849e490dbff08a0d27d8b2f',
  paystack_public_key: 'pk_test_076e3bc8454570b1f8e9c284dc34ee6df11e2096',
  remitta_api_key: 'QzAwMDAyNTE5MzV8NTcwODYyNDl8NTk0ZGFkZTE2OWM3N2UyZTMyMzlhYWJiZjM4NjdiZThhYWQyMTUxMWVlMjNiMjdjYmFmODVlNTZlMDFlNTA5ZGZjNDVhOTliM2Q2OGNhZmE0YmI3YzllODBhMTdmNmIxMDc4ZWRlMWM5MDc1OTc4ZGYxMDQ5ZjIxYWU2Mjc0YjQ=',
  remitta_mode: 'test',
  currentDivision: '',
  interswitch_mechant_code: 'VNA',
  interswitch_pay_item: 'Default_Payable_MX128354',
  interswitch_mode: 'TEST',
  currentCourtInfoEmail: 'info@judiciary.na.gov.ng',
  showJudiciarySuffix: true,
  showState: true,
  showDivision: false,
  showSupCourtTitle: true,
  supCourtTitle:'The Judiciary,',
  supCourtTitleColor:'red'
};
