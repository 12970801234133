import { Component, OnInit } from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environments/environment';

@Component({
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  public searchText: any;
  public data: any;
  public coreConfig: any;
  currentState = environment.currentState
  court_bg_path = environment.court_bg_path
  court_logo_path = environment.court_logo_path; 
  court_logo_path_main = environment.court_logo_path;
  courtTitle = environment.courtTitleSentence;
  showJudiciarySuffix = environment.showJudiciarySuffix;

  constructor(
    private _coreConfigService: CoreConfigService,
    private modalService: NgbModal,
    ) {
    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: false
        },
        customizer: false,
        enableLocalStorage: true
      }
    };
  }

  ngOnInit(): void {
    
  }

  
  // modal Basic
  modalOpen(modalBasic) {
    this.modalService.open(modalBasic);
  }

  // modal Open Vertically Centered
  modalOpenVC(modalVC) {
    this.modalService.open(modalVC, {
      centered: true,
      size: 'lg'
    });
  }

  // modal Open Backdrop Disabled
  modalOpenBD(modalBD) {
    this.modalService.open(modalBD, {
      backdrop: false,
      centered: true
    });
  }

  // modal Open Animation Disabled
  modalOpenAD(modalAD) {
    this.modalService.open(modalAD, {
      centered: true,
      windowClass: 'animation-disable',
      animation: false
    });
  }
  

}
